"use strict";

if($('body > .index').length > 0) {

    // if ($.cookie('intro') !== '1') {
    //     $(window).on( "load", function() {
    //         $('.intro').removeClass('hide');
    //         setTimeout(function(){
    //             $('.intro').addClass('hide');
    //             $.cookie('intro', '1', { expires: 365, path :'/' });
    //         }, 1000);
    //     });
    // }
}

function rwdImages(){
    if($('.product-photo').length > 0) {
        $('.product-photo').each(function(){
            var desktopUrl = $(this).data('photo');
            var windowWidth = $(window).width();
            if(windowWidth < 992){
                var mobileUrl = desktopUrl.replace('images','images-small');
                $(this).attr('src', mobileUrl);
            } else {
                $(this).attr('src', desktopUrl);
            }
        });
    }
}

rwdImages();

$(window).on('resize', function(){
    rwdImages();
});

var textOrphans = function () {
    $('.main-description').add('.middle .left .description p').add('.articles .articles-list .left h3').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

var listApp = function(){

    // qr popup

    $('.close-ico, .qr-popup').click(function(){
        $('.qr-popup').removeClass('show');
        localStorage.setItem("qr", 1);
    });

    $.ajax({
        url: "/popup",
        method: 'POST',
        contentType: "application/json"
    }).done(function(data) {
        if(data.active == 1) {
            $('.question-link .desktop').attr('src',data.media);
            $('.question-link .mobile').attr('src',data.media_mobile);
        }
    }).fail(function() {
        $('.qr-popup').removeClass('show');
    });

    $('.question-link img').on('load', function(){
        if(localStorage.getItem("qr") === null) {
            $('.qr-popup').addClass('show');
        }
    });

    // new offer popup temporary disabled

    // var actualOffer =  $('.month').data('month') + "-" + $('.year').data('year');
    // var viewedOffer = localStorage.getItem("viewedoffer");
    //
    // if(viewedOffer === null || viewedOffer !== actualOffer) {
    //     $('.new-offer-popup').addClass('show');
    // }
    //
    // $('.close-new-offer-ico, .new-offer-popup').click(function(){
    //     $('.new-offer-popup').removeClass('show');
    //     localStorage.setItem("viewedoffer", actualOffer);
    // });
    //
    // $('.new-offer-popup-inner').click(function(e){
    //     e.stopPropagation();
    // });

    //end



    //begin - special for date 1-6.01.2020 - dislable after 1.06.2020

    // var d = new Date();
    // var n = d.getDate();

    // var pathname = window.location.pathname;

    // if(pathname.split('/')[5] === "2020"){

        // $('.catalog-categories .special figcaption').text('7.01-31.01.2020');
        // $('.list-header-wrapper h3 strong').text('7.01-31.01.2020');
        // $('.disclaimer-date').text('7.01-31.01.2020');
        //
        // localStorage.setItem("specialDate", '7.01-31.01.2020');
    // }

    // if($('.date-range-info').length > 0 && (n >= 1 && n <= 6 )){
    //     localStorage.getItem("specialDate");
    //     $('.date-range-info').text('7.01-31.01.2020');
    // }

    //end - special for date 1-6.01.2020 - dislable after 1.06.2020


    function getList(){

        var tempItems = [];
        var totalItems = 0;
        var localDataStorage = [];

        if(JSON.parse(localStorage.getItem('list'))){
            localDataStorage = JSON.parse(localStorage.getItem('list'));
        }

        localDataStorage.forEach(function(element, index, array){
            tempItems.push(
                {
                    name: element.name,
                    desc: element.description,
                    count: element.counter,
                    price: element.total,
                    datefrom: element.datefrom,
                    dateto: element.dateto,
                    special: element.special
                }
            );
            totalItems += element.total;
        });

        var thisMonth = $('.monthname').data('monthname');
        var varietyDate = $('.variety-date').data('varietydate');
        var year = $('.year').data('year');
        var month = $('.month').data('month');

        var data = {
            items: tempItems,
            summary: totalItems,
            date: month + ' ' + year,
            varietyDate: varietyDate + ' ' + year
        };

        $('.download-pdf').addClass('show');
        $('.download-pdf .loader').show();

        // $('.download-pdf #link').click(function(){
        //     $('.download-pdf').removeClass('show');
        // });

        $.ajax({
            url: "/generate",
            method: 'POST',
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(function(data) {
            $('.download-pdf .loader').hide();

            if(localDataStorage.length > 0){
                $('.download-pdf .link-container').append('<a id="link" style="font-size:30px;" href="'+ data +'" download>Pobierz PDF</a>');
            } else {
                $('.download-pdf .link-container').append('<a id="link" style="font-size:30px;" download>Lista jest pusta.</a>');
            }
        }).fail(function() {
            $('.download-pdf .link-container').append('<a id="link" style="font-size:30px;" download>Błąd połączenia.</a>');
        });
    }

    // if (navigator.share) {
    //     $('.shopping-list-menu .button.share').css('display', 'inline-block');
    // } else {
    //     $('.shopping-list-menu .button.share').hide();
    // }

    function shareList(){

        $('.share-pdf .close-button').click(function(){
            $(this).closest('.share-pdf').removeClass('show');
            $('.share-pdf .link-container #link').remove();
        });

        var tempItems = [];
        var totalItems = 0;
        var localDataStorage = [];

        if(JSON.parse(localStorage.getItem('list'))){
            localDataStorage = JSON.parse(localStorage.getItem('list'));
        }

        localDataStorage.forEach(function(element, index, array){
            tempItems.push(
                {
                    name: element.name,
                    desc: element.description,
                    count: element.counter,
                    price: element.total,
                    datefrom: element.datefrom,
                    dateto: element.dateto,
                    special: element.special
                }
            );
            totalItems += element.total;
        });

        // var thisMonth = $('.monthname').data('monthname');
        // var varietyDate = $('.variety-date').data('varietydate');
        // var year = $('.year').data('year');
        //
        // var data = {
        //     items: tempItems,
        //     summary: totalItems,
        //     date: thisMonth,
        //     varietyDate: varietyDate,
        //     year: year
        // };

        var thisMonth = $('.monthname').data('monthname');
        var varietyDate = $('.variety-date').data('varietydate');
        var year = $('.year').data('year');
        var month = $('.month').data('month');

        var data = {
            items: tempItems,
            summary: totalItems,
            date: month + ' ' + year,
            varietyDate: varietyDate + ' ' + year
        };

        $('.share-pdf').addClass('show');
        $('.share-pdf .loader').show();

        $.ajax({
            url: "/generate-url",
            method: 'POST',
            data: JSON.stringify(data),
            contentType: "application/json"
        }).done(function(data) {

            if(localDataStorage.length > 0){

                if (navigator.share) {
                    navigator.share({
                        title: 'Lista zakupowa',
                        text: 'Udostępniam listę zakupową do apteki ',
                        url: data,
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => console.log('Error sharing', error));
                    $('.share-pdf .loader').hide();
                    $('.share-pdf').removeClass('show');
                }

            } else {

                $('.share-pdf .link-container').append('<a id="link" style="font-size:30px;" download>Lista jest pusta.</a>');
            }
        }).fail(function() {
            $('.share-pdf .loader').hide();
            $('.share-pdf .link-container').append('<a id="link" style="font-size:30px;" download>Błąd połączenia.</a>');
        });
    }

    $('.shopping-list-menu .button.share').click(function(){
        shareList();
    });

    $('.button.save').click(function(e) {
        getList();
    });

    function checkListLength(){

        if(JSON.parse(localStorage.getItem('list'))){
            var localDataCheck = JSON.parse(localStorage.getItem('list'));
            if(localDataCheck.length > 0){
                if(navigator.share) {
                    $('.button.share').css('display', 'inline-block');
                }
                $('.list-remove-all').css('display', 'block');
                $('.button.save').css('display', 'block');

            }  else {
                $('.list-remove-all').css('display', 'none');
                $('.button.save').css('display', 'none');
                $('.button.share').css('display', 'none');
            }
        } else {
            $('.list-remove-all').css('display', 'none');
            $('.button.save').css('display', 'none');
            $('.button.share').css('display', 'none');
        }
    }

    $('.run-shopping-list').click(function(){
        checkListLength();
    });

    $('.download-pdf .close-button').click(function(){
        $(this).closest('.download-pdf').removeClass('show');
        $('.download-pdf .link-container #link').remove();
    });

    $('.share-pdf .close-button').click(function(){
        $(this).closest('.share-pdf').removeClass('show');
        $('.share-pdf .link-container #link').remove();
    });

    //set plus to active
    function setPlusActive() {

        var localData = [];

        if(JSON.parse(localStorage.getItem('list'))) {
            // exist
            localData = JSON.parse(localStorage.getItem('list'));
            $(".grid-set .grid-item").each(function(i) {
                $(this).find('.add-product-button').removeClass('selected');
                var id = $(this).data('id');
                var that = $(this);

                localData.forEach(function (element, index, array) {
                    if (id === element.id) {
                        that.find('.add-product-button').addClass('selected');
                    }
                });
            });
        }

        if($(".grid-set .grid-item").length === $(".grid-set .grid-item .add-product-button.selected").length){
            $('.add-all').hide();
            $('.remove-all').show();
        } else {
            $('.add-all').show();
            $('.remove-all').hide();
        }
    }

    //add selected product to localStorage
    function addToLocal(id, name, description, price, datefrom, dateto, special, all){
        var itemExist = 0, localData = [];
        var item = {
            'id': id,
            'name': name,
            'description': description,
            'price': price,
            'counter': 1,
            'total': price,
            'datefrom': datefrom,
            'dateto': dateto,
            'special': special
        };
        if(JSON.parse(localStorage.getItem('list'))){
            // exist
            localData = JSON.parse(localStorage.getItem('list'));
            localData.forEach(function(element, index, array){
                if(item.id === element.id) {
                    itemExist += 1;
                    element.counter += 1;
                    element.total = ((parseFloat(element.price)) * element.counter);
                }
            });

            localStorage.setItem('list', JSON.stringify(localData));

            if(itemExist === 0) {
                localData.push(item);
                localStorage.setItem('list', JSON.stringify(localData));
                shoppingCounter();
            }
        } else {
            // dont exist
            localData.push(item);
            localStorage.setItem('list', JSON.stringify(localData));
        }
    }

    //remove selected product from localStorage
    function removeFromLocal(id){
        var localData = [];
        if(JSON.parse(localStorage.getItem('list'))) {
            // exist
            localData = JSON.parse(localStorage.getItem('list'));
            localData.forEach(function (element, index, array) {
                if (id === element.id) {
                    array.splice(index, 1);
                }
            });
            localStorage.setItem('list', JSON.stringify(localData));
            shoppingCounter();
        }
    }

    //update sidebar products counter and summary price
    function shoppingCounter(){
        setPlusActive();

        var counterAll = 0, totalAll = 0;

        if(JSON.parse(localStorage.getItem('list'))){
            var localData = JSON.parse(localStorage.getItem('list'));

            localData.forEach(function(element, index, array) {
                counterAll += element.counter;
                totalAll += element.total;
            });

            if(counterAll > 0){
                $('#shopping-list .counter').show();
                $('#shopping-list .counter').text(counterAll);
            } else {
                $('#shopping-list .counter').hide();
            }

            if(totalAll > 0){
                $('.shopping-list .summary-price').text(totalAll.toFixed(2).replace('.',',') + ' zł');
                $('.shopping-list .summary').show();
            } else {
                $('.shopping-list .summary').hide();
            }
        }

        if(counterAll === 0){
            $('#shopping-list .counter').text('0');
            $('.shopping-list .summary-price').text('0,00 zł');

            $('<div class="empty-list"><p>Lista jest pusta</p></div>').insertBefore('#list-wrapper');
        } else {
            $('.empty-list').remove();
        }
    }

    //update sidebar products counter on page load
    shoppingCounter();

    function updateList(){
        if(JSON.parse(localStorage.getItem('list'))){
            var listWrapper = $('#list-wrapper').html('');
            var localData = JSON.parse(localStorage.getItem('list'));

            localData.forEach(function(element, index, array){

                if(element.total == 0) {

                    if(element.special == true){
                        var li = $('<li class="list-item" data-id="' + element.id + '">\n' +
                            '                        <div class="left">\n' +
                            '                            <h5>Oferta ograniczona czasowo<br /><strong>' + element.datefrom + ' - '+ element.dateto + '</strong></h5>\n' +
                            '                            <h4>' + element.name + '</h4>\n' +
                            '                            <p>' + element.description + '</p>\n' +
                            '                        </div>\n' +
                            '                        <div class="right">\n' +
                            '                            <span class="counter">' + element.counter + '</span>\n' +
                            '                            <a href="#" class="plus"></a>\n' +
                            '                            <a href="#" class="minus"></a>\n' +
                            '                            <span class="price-one"></span>\n' +
                            '                            <a href="#" class="trash"></a>\n' +
                            '                        </div>\n' +
                            '                    </li>');
                    } else {
                        var li = $('<li class="list-item" data-id="' + element.id + '">\n' +
                            '                        <div class="left">\n' +
                            '                            <h4>' + element.name + '</h4>\n' +
                            '                            <p>' + element.description + '</p>\n' +
                            '                        </div>\n' +
                            '                        <div class="right">\n' +
                            '                            <span class="counter">' + element.counter + '</span>\n' +
                            '                            <a href="#" class="plus"></a>\n' +
                            '                            <a href="#" class="minus"></a>\n' +
                            '                            <span class="price-one"></span>\n' +
                            '                            <a href="#" class="trash"></a>\n' +
                            '                        </div>\n' +
                            '                    </li>');
                    }

                } else {

                    if(element.special == true){
                        var li = $('<li class="list-item" data-id="' + element.id + '">\n' +
                            '                        <div class="left">\n' +
                            '                            <h5>Oferta ograniczona czasowo<br /><strong>' + element.datefrom + ' - '+ element.dateto + '</strong></h5>\n' +
                            '                            <h4>' + element.name + '</h4>\n' +
                            '                            <p>' + element.description + '</p>\n' +
                            '                        </div>\n' +
                            '                        <div class="right">\n' +
                            '                            <span class="counter">' + element.counter + '</span>\n' +
                            '                            <a href="#" class="plus"></a>\n' +
                            '                            <a href="#" class="minus"></a>\n' +
                            '                            <span class="price-one">' + element.total.toFixed(2).replace('.',',') + '&nbsp;zł</span>\n' +
                            '                            <a href="#" class="trash"></a>\n' +
                            '                        </div>\n' +
                            '                    </li>');
                    } else {
                        var li = $('<li class="list-item" data-id="' + element.id + '">\n' +
                            '                        <div class="left">\n' +
                            '                            <h4>' + element.name + '</h4>\n' +
                            '                            <p>' + element.description + '</p>\n' +
                            '                        </div>\n' +
                            '                        <div class="right">\n' +
                            '                            <span class="counter">' + element.counter + '</span>\n' +
                            '                            <a href="#" class="plus"></a>\n' +
                            '                            <a href="#" class="minus"></a>\n' +
                            '                            <span class="price-one">' + element.total.toFixed(2).replace('.',',') + '&nbsp;zł</span>\n' +
                            '                            <a href="#" class="trash"></a>\n' +
                            '                        </div>\n' +
                            '                    </li>');
                    }
                }

                li.appendTo(listWrapper);
                $('#list-wrapper .list-item .left p').ellipsis({ lines: 1, responsive: true });
                shoppingCounter();
            });
        }

        function changeCounter(clickedId, direction, totalHtml){
            var localData = JSON.parse(localStorage.getItem('list'));
            localData.forEach(function(element, index, array){
                if(clickedId === element.id) {

                    if(direction === 'plus'){
                        element.counter += 1;
                        element.total = ((parseFloat(element.price)) * element.counter);
                        totalHtml.siblings('.counter').text((parseInt(totalHtml.siblings('.counter').text()) + 1));

                        if(element.total > 0){
                            totalHtml.siblings('.price-one').text(element.total.toFixed(2).replace('.',',') + ' zł');
                        }
                    }

                    if(direction === 'minus'){
                        if(element.counter > 1){
                            element.counter -= 1;
                            element.total = ((parseFloat(element.price)) * element.counter);
                            totalHtml.siblings('.counter').text((parseInt(totalHtml.siblings('.counter').text()) - 1));

                            if(element.total > 0){
                                totalHtml.siblings('.price-one').text(element.total.toFixed(2).replace('.',',') + ' zł');
                            }

                        }
                    }
                    localStorage.setItem('list', JSON.stringify(localData));
                    shoppingCounter();
                }
            });
        }

        $('#list-wrapper .plus').click(function(e){
            var clickedId = $(this).closest('.list-item').data('id');
            changeCounter(clickedId, 'plus', $(this));
            e.preventDefault();
        });

        $('#list-wrapper .minus').click(function(e){
            var clickedId = $(this).closest('.list-item').data('id');
            changeCounter(clickedId, 'minus', $(this));
            e.preventDefault();
        });

        function removeFromList(id){
            var localData = JSON.parse(localStorage.getItem('list'));
            localData.forEach(function(element, index, array){
                if(id === element.id) {
                    array.splice(index, 1);
                    localStorage.setItem('list', JSON.stringify(localData));
                }
            });
        }

        $('#list-wrapper .trash').click(function(e){
            var clickedId = $(this).closest('.list-item').data('id');
            removeFromList(clickedId);
            $(this).closest('.list-item').slideUp(function(){
                $(this).remove();
            });
            shoppingCounter();
            checkListLength();
        });
    }
    updateList();

    function addProductAnimation() {
        $('.sidebar .counter').addClass('shake')
            .delay(1000)
            .queue(function(next){
                $(this).removeClass('shake');
                next();
            });

        $('.sidebar #shopping-list').addClass('shake-mobile')
            .delay(1000)
            .queue(function(next){
                $(this).removeClass('shake-mobile');
                next();
            });
    }

    $('.add-product-button').click(function(e){

        let id = $(this).closest('.grid-item').data('id');
        let name = $(this).closest('.grid-item-inner').find('.name').data('name');
        let description = $(this).closest('.grid-item-inner').find('.main-description').data('description');
        let price = $(this).closest('.grid-item-inner').find('.price').data('price');
        let datefrom = $(this).closest('.grid-item').data('datefrom');
        let dateto = $(this).closest('.grid-item').data('dateto');
        let special = $(this).closest('.grid-item').data('special');

        addProductAnimation();
        addToLocal(id, name, description, price, datefrom, dateto, special, false);
        updateList();
        e.preventDefault();

    });

    $('.add-all').click(function(){

        // $('.add-all').hide();
        // $('.remove-all').show();

        addProductAnimation();

        $('.list-of-set .grid-item').each(function() {

            var id = $(this).data('id');
            var name = $(this).find('.name').data('name');
            var description = $(this).find('.main-description').data('description');
            var price = $(this).find('.price').data('price');
            let datefrom = $(this).closest('.grid-item').data('datefrom');
            let dateto = $(this).closest('.grid-item').data('dateto');
            let special = $(this).closest('.grid-item').data('special');

            addToLocal(id, name, description, price, datefrom, dateto, special, true);
            updateList();
        });
    });

    // remove all from one page
    $('.remove-all').click(function(e){
        // $('.add-all').show();
        // $('.remove-all').hide();

        addProductAnimation();

        $('.list-of-set .grid-item').each(function() {
            var id = $(this).data('id');
            removeFromLocal(id);
            updateList();
        });
        e.preventDefault();
    });

    //remove all from shopping-list
    $('.list-remove-all').click(function(e){
        $('#list-wrapper .list-item').each(function() {
            var id = $(this).data('id');
            removeFromLocal(id);
            updateList();
        });
        checkListLength();
        $(this).fadeOut();
        e.preventDefault();
    });

    // reset list if we have next month

    // temporary disabled:

    // var logged = $('.is-logged').data('login');
    //
    // console.log($('.is-logged').data('login'));
    //
    // if(logged == 1){
    //
    //     if((new Date().getMonth() + 1) != $('.month').data('month') && (new Date().getMonth() + 2) != $('.month').data('month')){
    //
    //         $('#list-wrapper .list-item').each(function() {
    //             var id = $(this).data('id');
    //             removeFromLocal(id);
    //             updateList();
    //         });
    //     }
    // } else {
    //
    //     if((new Date().getMonth() + 1) != $('.month').data('month')){
    //
    //         $('#list-wrapper .list-item').each(function() {
    //             var id = $(this).data('id');
    //             removeFromLocal(id);
    //             updateList();
    //         });
    //     }
    // }
};

var setsGrid = function() {
    $('.grid-sets').masonry({
        itemSelector: '.grid-item'
    });

    $('.product-details a').click(function(e){
        $(this).closest('.grid-item-inner').toggleClass('show');
        e.preventDefault();
    });

    if($('.sets .list-of-set').length > 0) {
        $('.sets .title').addClass('product-list');
        $('.sets .title').removeClass('category-list');
    }

    if($('.sets .list-of-sets').length > 0) {
        $('.sets .title').removeClass('product-list');
        $('.sets .title').addClass('category-list');
    }

    $('.articles-list .article-description').ellipsis({ lines: 5, responsive: true });

    $('.grid-item-inner .product-image').matchHeight();
    $('.grid-item-inner .titles-wrapper h3').matchHeight();
    $('.grid-item-inner .titles-wrapper h4').matchHeight();
    $('.grid-item-inner .titles-wrapper').matchHeight();
    $('.grid-item-inner .product-details').matchHeight();
    //$('.grid-item-inner .product-description').matchHeight();
    $('.articles-list a').matchHeight();
    $('.recommended-list a').matchHeight();
    $('.catalog-categories .ico-wrapper').matchHeight();

    $('.short-description').ellipsis({ lines: 5, responsive: true });

};

var articlesGrid = function() {
    $('.articles .articles-list .left').matchHeight();

    $.fn.matchHeight._afterUpdate = function(event, groups) {
        textOrphans();
    }


};

var catalogGrid = function() {
    $('.index .middle li a').matchHeight();
};

var showList = function() {

    $('#shopping-list a').click(function(e){
        $('.shopping-list').fadeIn(300);
        $('body').addClass('disable-scroll');
        e.preventDefault();
    });

    $('#close-list a').click(function(e){
        $('.shopping-list').fadeOut(300);
        $('body').removeClass('disable-scroll');
        e.preventDefault();
    })
};

var searchSection = function(e) {
    $('.search-section button[type="submit"]').click(function(){
        if($('.search-section').hasClass('active') || $(window).width() > 768){

        } else {
            $('.search-section').addClass('active');
            return false;
        }
    });
};



// var saveListAs = function() {
//
//     $('.shopping-list-menu .button, #shopping-list a').click(function(e) {
//
//     var that = $(this);
//
//   (function () {
//     function b64toBlob(b64Data, contentType, sliceSize) {
//       contentType = contentType || '';
//       sliceSize = sliceSize || 512;
//
//       var byteCharacters = atob(b64Data);
//       var byteArrays = [];
//
//       for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//         var slice = byteCharacters.slice(offset, offset + sliceSize);
//
//         var byteNumbers = new Array(slice.length);
//         for (var i = 0; i < slice.length; i++) {
//           byteNumbers[i] = slice.charCodeAt(i);
//         }
//         var byteArray = new Uint8Array(byteNumbers);
//         byteArrays.push(byteArray);
//       }
//
//       var blob = new Blob(byteArrays, {type: contentType});
//       return blob;
//     }
//
//     var element = document.getElementById("shopping-list-content");
//     // Generate screenshot and download
//     html2canvas(element, {
//       logging: true,
//       letterRendering: 1,
//       allowTaint: false,
//       useCORS: true,
//       scale: 2
//     }).then(function (canvas) {
//       // Generate the base64 representation of the canvas
//       var base64image = canvas.toDataURL("image/png");
//
//       // Split the base64 string in data and contentType
//       var block = base64image.split(";");
//       // Get the content type
//       var mimeType = block[0].split(":")[1];// In this case "image/png"
//       // get the real base64 content of the file
//       var realData = block[1].split(",")[1];// For example:  iVBORw0KGgouqw23....
//
//       // Convert b64 to blob and store it into a variable (with real base64 as value)
//       var canvasBlob = b64toBlob(realData, mimeType);
//
//       if(that.hasClass('save')){
//         // Generate file download
//         window.saveAs(canvasBlob, "lista-zakupowa.png");
//       }
//
//       if(that.hasClass('run-shopping-list')){
//
//         var newFileTest = new File([canvasBlob], "listazakupowa.png", {
//           type: "image/png",
//         });
//
//         var filesArrayTest = [];
//         filesArrayTest.push(newFileTest);
//
//         if (navigator.canShare && navigator.canShare( { files: filesArrayTest } )) {
//           $('.shopping-list-menu .button.share').css('display', 'inline-block');
//         } else {
//           $('.shopping-list-menu .button.share').hide();
//         }
//       }
//
//       if(that.hasClass('share')){
//         // share on supported mobile devices
//         var newFile = new File([canvasBlob], "listazakupowa.png", {
//           type: "image/png",
//         });
//
//         var filesArray = [];
//         filesArray.push(newFile);
//
//         if (navigator.canShare && navigator.canShare( { files: filesArray } )) {
//             navigator.share({
//               files: filesArray,
//               title: 'Lista zakupowa',
//               text: 'Udostępniam listę zakupową do apteki',
//             })
//               .then(() => console.log('Share was successful.'))
//               .catch((error) => console.log('Sharing failed', error));
//
//         } else {
//           console.log("Not supported");
//         }
//       }
//       e.preventDefault();
//
//     });
//   })();
//
//   });
// };

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', { expires: 365, path :'/' });
        });
    });
};

$(function () {
    setsGrid();
    catalogGrid();
    articlesGrid();
    showList();
    listApp();
    searchSection();
    //saveListAs();
    cookies();
    textOrphans();

    // if($('.index').length > 0){
    //     if($('.index .catalog-categories').data('monthpart') == 1){
    //       $('.index .catalog-categories ul li:last-child .ico-wrapper img').attr('src','/img/part1.png');
    //     } else {
    //         $('.index .catalog-categories ul li:last-child .ico-wrapper img').attr('src','/img/part2.png');
    //     }
    // }


});
